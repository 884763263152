<template>
    <section>
        <b-row>
            <b-col md="12">
                <div class="bg-frecce py-5 px-3 rounded mb-2">
                    <h2 class="text-white">
                        Benvenuto su
                        <br />
                        <b>Smart 378</b>
                    </h2>

                    <p class="text-white">

                    </p>
                </div>
            </b-col>
        </b-row>

        <!-- <b-row>
            <b-col md="6">
                <statistic-card-with-area-chart
                    v-if="data.subscribersGained"
                    icon="GridIcon"
                    :statistic="kFormatter(data.subscribersGained.analyticsData.subscribers)"
                    statistic-title="Clienti totali"
                    :chart-data="data.subscribersGained.series"
                />
            </b-col>

            <b-col md="6">
                <statistic-card-with-area-chart
                    v-if="data.subscribersGained"
                    icon="CheckSquareIcon"
                    :statistic="kFormatter(data.subscribersGained.analyticsData.subscribers)"
                    statistic-title="Progetti salvati"
                    :chart-data="data.subscribersGained.series"
                />
            </b-col>
        </b-row> -->

        <div class="row">
            <div class="text-center p-2 col-xl-4 col-xs-12 col-sm-12">
                <div class="card p-3">
                    <div class="card-body">
                        <div class="d-flex flex-column align-items-center">
                            <check-square-icon size="3x" />
                            <b-link class="btn btn-primary mt-3" to="/new_project_type">Nuovo Progetto</b-link>
                        </div>
                    </div>
                </div>
            </div>

            <div class="text-center p-2 col-xl-4 col-xs-12 col-sm-12">
                <div class="card p-3">
                    <div class="card-body">
                        <div class="d-flex flex-column align-items-center">
                            <save-icon size="3x" />
                            <b-link class="btn btn-primary mt-3" to="/saved_projects">Progetti Salvati</b-link>
                        </div>
                    </div>
                </div>
            </div>

            <div class="text-center p-2 col-xl-4 col-xs-12 col-sm-12">
                <div class="card p-3">
                    <div class="card-body">
                        <div class="d-flex flex-column align-items-center">
                            <grid-icon size="3x" />
                            <b-link class="btn btn-primary mt-3" to="/clients">Clienti</b-link>
                        </div>
                    </div>
                </div>
            </div>

            
        </div>
    </section>
</template>

<script>
import { BRow, BCol, BButton, BLink } from 'bootstrap-vue'

import StatisticCardWithAreaChart from '@core/components/statistics-cards/StatisticCardWithAreaChart.vue'
import { kFormatter } from '@core/utils/filter'

import { CheckSquareIcon, SaveIcon, GridIcon } from 'vue-feather-icons'
import { STORAGE_URL } from '@/config'

export default {

    components: {
        BRow,
        BCol,
        BLink,
        BButton,
        StatisticCardWithAreaChart,
        CheckSquareIcon, SaveIcon, GridIcon
    },

    data() {
        return {
            STORAGE_URL,
            data: {},
        }
    },
    created() {
        // data
        this.$http.get('/analytics/data')
            .then(response => { this.data = response.data })
    },

    methods: {
        kFormatter,
    },

}
</script>

<style scoped>
.bg-frecce {
    background: url("../assets/images/frecce-bianche.svg"),
        linear-gradient(46.62deg, #018001 0%, #9bc69b 93.64%);
    background-repeat: no-repeat !important;
    background-size: contain !important;
    background-position: right;
}
</style>
